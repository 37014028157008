import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cost-review-and-approval"
    }}>{`Cost Review and Approval`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`In cases where a tasking request has not been "preapproved", you will need to review the cost estimate of the tasking request and indicate whether you would like to approve the cost and have the tasking request submitted to the scheduler or reject the tasking request. The tasking request will not be submitted for scheduling until you approve. Tasking requests can only be approved when they have reached the `}<inlineCode parentName="p">{`review`}</inlineCode>{` status (see `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/constellation-tasking/tasking-request-status"
      }}>{`Tasking Request Status`}</a>{`). When a tasking request has been approved, it will move to the `}<inlineCode parentName="p">{`submitted`}</inlineCode>{` status until it is evaluated with the tasking scheduler. You can skip the cost review step by setting the `}<inlineCode parentName="p">{`preapproval`}</inlineCode>{` flag to `}<inlineCode parentName="p">{`true`}</inlineCode>{` when submitting a tasking request.`}</p>
    <h2 {...{
      "id": "reviewing-cost-estimates"
    }}>{`Reviewing Cost Estimates`}</h2>
    <h3 {...{
      "id": "single-tasking-request"
    }}>{`Single Tasking Request`}</h3>
    <p>{`The cost estimate information will be included with the tasking request status once it has been calculated. You can find the status of the single task request using the task endpoint and the tasking request id:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/task/{{taskingrequestId}}
`}</code></pre>
    <p>{`If the tasking request is not yet ready for review, the `}<inlineCode parentName="p">{`transactionStatus`}</inlineCode>{` in the response will be ''. When the cost estimate information is available, the `}<inlineCode parentName="p">{`transactionStatus`}</inlineCode>{` will be set to `}<inlineCode parentName="p">{`pending-customer-approval`}</inlineCode>{`. You will find the cost estimate information in `}<inlineCode parentName="p">{`order`}</inlineCode>{`: `}</p>
    <pre><code parentName="pre" {...{}}>{`"order": {
    "summary": {
        "total": "$1,000.00",
        "subtotal": "$1,000.00"
    },
    "lineItems": [
        {
            "taskId": "45b0e94b-6107-4671-ab13-d526e45d88c2",
            "accountingSize": {
                "unit": "sqkm",
                "value": 100
            }
        }
    ],
    "archiveHoldback": "none",
    "managedOrganizationIds": [
        "*"
    ]
},
`}</code></pre>
    <h3 {...{
      "id": "repeat-tasking-request"
    }}>{`Repeat Tasking Request`}</h3>
    <p>{`The cost estimate information will be included with the repeat tasking request status once it has been calculated. You can find the status of the repeat request using the repeat-requests endpoint and the repeat tasking request id:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/repeat-requests/{{repeatrequestId}}
`}</code></pre>
    <p>{`Once the repeat tasking request status `}<inlineCode parentName="p">{`code`}</inlineCode>{` equals `}<inlineCode parentName="p">{`review`}</inlineCode>{`, you will find the cost estimate information in `}<inlineCode parentName="p">{`costPerImageEstimate`}</inlineCode>{`: `}</p>
    <pre><code parentName="pre" {...{}}>{`"costPerImageEstimate": "$1,000.00",
`}</code></pre>
    <h2 {...{
      "id": "approving-or-rejecting"
    }}>{`Approving or Rejecting`}</h2>
    <h3 {...{
      "id": "single-tasking-request-1"
    }}>{`Single Tasking Request`}</h3>
    <p>{`Based on the cost estimate, you can decide whether to approve or reject the request. To do this you will send a request to the status endpoint with `}<inlineCode parentName="p">{`status`}</inlineCode>{` set to either `}<inlineCode parentName="p">{`approved`}</inlineCode>{` or `}<inlineCode parentName="p">{`rejected`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`PATCH https://api.capellaspace.com/task/{{taskingrequestId}}/status

{
    "status": "approved"
}
`}</code></pre>
    <h3 {...{
      "id": "repeat-tasking-request-1"
    }}>{`Repeat Tasking Request`}</h3>
    <p>{`Based on the cost estimate, you can decide whether to approve or reject the repeat request. To do this you will send a request to the status endpoint with `}<inlineCode parentName="p">{`code`}</inlineCode>{` set to either `}<inlineCode parentName="p">{`submitted`}</inlineCode>{` or `}<inlineCode parentName="p">{`rejected`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`PATCH https://api.capellaspace.com/repeat-requests/{{repeatrequestId}}/status

{
    "code": "submitted"
}
`}</code></pre>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on tasking request cost review and approval, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/tasking"
      }}>{`Tasking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      